// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// you can also import a translation file
import { German } from "flatpickr/dist/l10n/de.js";

// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/flatpickr.css";

export default class extends Flatpickr {
  initialize() {
    this.config = {
      enableTime: true,
      time_24hr: true,
      locale: German,

      altFormat: "d.m.Y H:i",
      altInput: true
    };
  }

  connect() {
    super.connect();
    this.parentController = this.getControllerByIdentifier("datetimerange");
    this.parentController.setFlatpickrInstance(this.fp);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  change(selectedDates, dateStr, instance) {
    this.element.dispatchEvent(new CustomEvent('dateChanged', {
      detail: {
        selectedDate: selectedDates[0],
      },
      bubbles: true
    }));
  }
}
