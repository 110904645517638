/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'application.css';

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import "trix/dist/trix.css";

require("@rails/actiontext")
import Trix from "trix"

const COLORS = {
  fgColor1: 'rgb(185, 94, 6)',
  fgColor2: 'rgb(207, 0, 0)',
  fgColor3: 'rgb(124, 58, 237)',
  fgColor4: 'rgb(5, 98, 185)',
  fgColor5: 'rgb(13, 135, 25)',
  fgColor6: 'rgb(148, 82, 22)',
};

document.addEventListener("trix-before-initialize", () => {
  Object.values(COLORS).forEach((color, i) => {
    Trix.config.textAttributes[`fgColor${(i + 1)}`] = { style: { color: color }, inheritable: true, parser: e => e.style.color == color }
  })
});

document.addEventListener("trix-initialize", function (event) {
  var buttonHTML, buttonGroup
  buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-color" data-trix-action="x-color" title="Color" tabindex="-1">Color</button>'
  buttonGroup = event.target.toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")
  buttonGroup.insertAdjacentHTML("beforeend", buttonHTML)

  var dialogElement = event.target.toolbarElement.querySelector("[data-trix-dialogs]")
  var dialogHTML = `
  <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color" data-trix-dialog-attribute="x-color">
    <div class="trix-dialog__link-fields">
      <input type="text" name="x-color" class="trix-dialog-hidden__input" data-trix-input>
      <div class="trix-button-group">
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor1" data-trix-method="hideDialog"></button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor2" data-trix-method="hideDialog"></button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor3" data-trix-method="hideDialog"></button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor4" data-trix-method="hideDialog"></button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor5" data-trix-method="hideDialog"></button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor6" data-trix-method="hideDialog"></button>
      </div>
    </div>
  </div>
`
  dialogElement.insertAdjacentHTML("beforeend", dialogHTML)

  var colorButtons = event.target.toolbarElement.querySelectorAll('.trix-dialog--color .trix-button-group button');
  var trixEditor = event.target.editor;

  Array.from(colorButtons).forEach((button, i) => {
    button.addEventListener('click', function (event) {
      const element = event.target
      if (element.hasAttribute('data-trix-attribute')) {
        const selectedAttribute = element.getAttribute('data-trix-attribute')
        //unselect the previous selected item.
        Object.keys(COLORS)
          .filter((key) => key !== selectedAttribute)
          .forEach((key) => {
            return trixEditor.deactivateAttribute(key)
          })
      }
    })
  });

  // expand selection if it's a placeholder to include the {{}} brackets
  event.target.addEventListener('trix-selection-change', function (event) {
    var trixEditor = event.target.editor
    var startOfSelection = trixEditor.getSelectedRange()[0];
    var endOfSelection = trixEditor.getSelectedRange()[1];

    if (startOfSelection == endOfSelection) {
      return
    }

    var documentString = event.target.editor.getDocument().toString()
    if ((documentString.charAt(startOfSelection - 1) == '{' && documentString.charAt(startOfSelection - 2) == '{') && (documentString.charAt(endOfSelection) == '}' && documentString.charAt(endOfSelection + 1) == '}')) {
      trixEditor.setSelectedRange([startOfSelection - 2, endOfSelection + 2])
    }
  });
});

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))
