import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  get enableOnChange() {
    return (this.data.get("enableOnChange") === 'true')
  }

  get inputElement() {
    if(this.hasInputTarget) {
      return this.inputTarget
    } else {
      if(this.element instanceof HTMLInputElement) {
        return this.element
      } else {
        this.element.getElementsByTagName('input')[0]
      }
    }
  }

  connect() {
    if(this.enableOnChange && !this.inputElement.value) {
      this.disable()
    }
  }

  update(selectedOption) {
    let values = JSON.parse(this.data.get("values"))
    this.element.setAttribute(this.data.get("attribute"), values[selectedOption])

    if(this.enableOnChange) {
      this.enable()
    }
  }

  disable() {
    this.element.classList.add('input-disabled')
    this.inputElement.setAttribute('disabled', 'disabled')
  }

  enable() {
    this.inputElement.removeAttribute('disabled')
    this.element.classList.remove('input-disabled')
  }
}
