import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option"]

  connect() {
    this.toggle()
  }

  toggle() {
    for (let option of this.optionTargets) {
      let radio = option.querySelector('input[type="radio"]')
      let input = radio.nextElementSibling.querySelector('input')
      if (radio.checked == false) {
          input.disabled = true
          input.parentNode.classList.add('input-disabled')
      } else {
          input.disabled = false
          input.parentNode.classList.remove('input-disabled')
      }
    }
  }
}
