import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "startDate", "endDate", "rangeInfo" ];
  static startDateInstance;
  static endDateInstance;

  setFlatpickrInstance(instance) {
    if(instance.input.id == 'campaign_start_at') {
      this.startDateInstance = instance;
    } else if (instance.input.id == 'campaign_end_at') {
      this.endDateInstance = instance;
    }
  }

  dateChanged(event) {
    if(event.target == this.startDateTarget) {
      this.restrictEndDate(this.startDateInstance.selectedDates[0]);
      this.updateRangeInfo('Wähle noch ein Enddatum.');
    } else if (event.target == this.endDateTarget) {
      this.updateRangeInfo('Super...');
    }
  }

  restrictEndDate(selectedStartDate) {
    this.endDateInstance.set('minDate', selectedStartDate);
    this.endDateInstance.jumpToDate(selectedStartDate);
  }

  updateRangeInfo(text) {
    this.rangeInfoTarget.textContent = text;
  }
}
