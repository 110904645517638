import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "select",
    "revealingSpace",
  ]

  connect() {
    this.selectTarget.value = this.data.get("selected") || "unspecified"
    this.reveal()
  }

  reveal(event) {
    let template = this.selectTarget.selectedOptions[0].querySelector('template')

    if (template) {
      this.revealingSpaceTarget.innerHTML = template.innerHTML
    } else {
      this.revealingSpaceTarget.innerHTML = ""
    }
  }
}
