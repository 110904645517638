// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// you can also import a translation file
import { German } from "flatpickr/dist/l10n/de.js";

// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/flatpickr.css";

export default class extends Flatpickr {
  initialize() {
    this.config = {
      enableTime: false,
      locale: German,

      altFormat: "d.m.Y",
      altInput: true,
      allowInput: true
    };
  }
}
