import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "option",
    "content",
  ]

  selectTab(event) {
    this.highlightSelectedOption(event.target)
    if(event.detail.content) {
      this.showContent(event.detail.content)
    } else {
      this.hideContent()
    }
  }

  showContent(content) {
    this.contentTarget.innerHTML = content
    this.contentTarget.classList.remove('hidden')
  }

  hideContent() {
    this.contentTarget.innerHTML = ""
    this.contentTarget.classList.add('hidden')
  }

  highlightSelectedOption(selectedOption) {
    this.optionTargets.forEach(option => option.classList.remove('bg-beige'))
    selectedOption.closest('div').classList.add('bg-beige')
  }
}
