import { Controller } from "stimulus"

export default class extends Controller {

    toggle(event) {
        if(window.scrollY > 0) {
            this.element.classList.add(this.data.get("class"));
        } else {
            this.element.classList.remove(this.data.get("class"));
        }
    }
}
