import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["template", "form", "saveButton"]

  connect() {
    this.toggleSaveButton()
  }

  add(event) {
    event.preventDefault()
    
    let newForm = this.templateTarget.content.cloneNode(true)
    this._incrementAttributesIndex(newForm.firstElementChild)
    this.element.insertBefore(newForm, event.target)

    this.toggleSaveButton()
  }

  remove(event) {
    event.preventDefault()
    event.target.closest('[data-target="currencies.form"]').remove()
  }

  toggleSaveButton() {
    if(this.formTargets.length > 0) {
      this.saveButtonTarget.classList.remove('hidden')
    } else {
      this.saveButtonTarget.classList.add('hidden')
    }
  }

  _incrementAttributesIndex(element) {
    element.innerHTML = element.innerHTML.replace(/\[currency_attributes\]\[0\]/g, '[currency_attributes]['+ this.formTargets.length +']')
    element.innerHTML = element.innerHTML.replace(/currency_attributes_0/g, 'currency_attributes_'+ this.formTargets.length)
  }
}
