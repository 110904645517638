import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "donorSelection", "donorDetails", "donorAutocomplete", "donorForm", "createIndicator" ]

  connect() {
    if(this.createIndicatorTarget.value == "true") {
      this.donorAutocompleteTarget.classList.add('hidden')
      this.donorFormTarget.classList.remove('hidden')
    }

    if(this.donorSelectionTarget.value) {
      this.displayDetails(this.donorSelectionTarget.value)
    }
  }

  handleChangeEvent(event) {
    this.displayDetails(event.detail.value)
  }

  displayDetails(donorId) {
    fetch("/persons/previews/"+donorId)
      .then(response => response.text())
      .then(html => {
        this.donorDetailsTarget.innerHTML = html
      })
  }

  showDonorForm(event) {
    event.preventDefault()
    this.donorAutocompleteTarget.classList.add('hidden')
    this.donorFormTarget.classList.remove('hidden')
    this.createIndicatorTarget.value = true
    this.donorFormTarget.querySelector('input').focus()
  }

  showDonorAutocomplete(event) {
    event.preventDefault()
    this.donorFormTarget.classList.add('hidden')
    this.donorAutocompleteTarget.classList.remove('hidden')
    this.createIndicatorTarget.value = false
    this.donorAutocompleteTarget.querySelector('input[data-target="autocomplete.input"]').focus()
  }
}
