import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "template",
    "input"
  ]

  connect() {
    if(this.inputTarget.checked) {
      setTimeout(() => this.displayContent())
    }
  }

  displayContent() {
    var content = ""
    if (this.hasTemplateTarget) {
      content = this.templateTarget.innerHTML
    }

    const event = new CustomEvent('tab-selected', {
      bubbles: true,
      detail: {
        content: content
      }
    })
    this.element.dispatchEvent(event)
  }
}
