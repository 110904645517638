import { Controller } from "stimulus"

export default class extends Controller {
  open(event) {
    if (! this._is_a_link_tag(event.target.tagName)) {
      window.location = this.data.get('url')
    }
  }

  _is_a_link_tag(tag) {
    return tag.toLowerCase() === 'a'
  }
}
