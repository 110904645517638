import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "search",
  ]

  connect() {
    document.addEventListener("keyup", this.detectShortcut.bind(this))
  }

  disconnect() {
    document.removeEventListener("keyup", this.detectShortcut)
  }

  goTo(event) {
    var path = event.detail.value
    window.location.href = path;
  }

  detectShortcut(event) {
    var key = event.which || event.keyCode
    if(event.ctrlKey && key == 32) {
      this.searchTarget.focus()
      this.searchTarget.select()
    }
  }
}
