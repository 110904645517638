import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filter", "result"]

  filter() {
    this.resultTarget.classList.add('opacity-25')

    const baseUrl = this.data.get("url") || window.location.pathname
    const url = `${baseUrl}?${this.params}`

    //quickfix for modals...
    if (this.element.closest("[data-controller='modal'") == null) {
      Turbolinks.clearCache()
      Turbolinks.visit(url)
    } else {
      fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
      })
        .then(response => response.text())
        .then(html => this.element.parentElement.innerHTML = html)
    }
  }

  get params() {
    let params = []
    this.filterTargets.forEach(target => {
      if (target.type === 'checkbox' || target.type === 'radio') {
        if (target.checked) {
          params.push(`${target.name}=${target.value}`)
        }
      } else {
        params.push(`${target.name}=${target.value}`)
      }
    })
    return params.join("&")
  }
}
