import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "button"]

  static closedClasses = null
  static openClasses = null

  connect() {
    this.closedClasses = this.element.classList.value.split(" ");
    this.openClasses = this.data.get('open-classes').split(" ");
  }

  toggle() {
    this.contentTarget.classList.toggle('hidden');
    this.buttonTarget.classList.toggle('upside-down');

    if (this.openClasses) {
      this.element.classList = "";
      if (this.contentTarget.classList.contains("hidden")) {
        this.closedClasses.forEach(closedClass => {
          this.element.classList.add(closedClass);
        });
      } else {
        this.openClasses.forEach(openClass => {
          this.element.classList.add(openClass);
        });
      }
    }
  }
}
