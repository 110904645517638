import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get("url"), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }
}
