import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "changeReceiver" ]

  broadcast(event) {
    let _this = this
    this.changeReceiverTargets.forEach(function(changeReceiver) {
      let receiverController = _this.application.getControllerForElementAndIdentifier(changeReceiver, 'select-change-receiver')
      if(receiverController) {
        receiverController.update(event.target.value)
      }
    })
  }

}
