import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["totalAmount", "percentSplitValue", "amountSplitValue", "form", "availableAmount", "splitButton"]

  static availableAmount

  connect() {
    this.updateAvailableAmount()
  }

  handleSplitChange(event) {
    event.preventDefault()
    this.updateAvailableAmount()
    if(this.availableAmount > 0 && this.formTargets.length == 1) {
      this.split(event)
    }
  }

  handleTotalAmountChange(event) {
    if(this.formTargets.length == 1) {
      this.amountSplitValueTarget.value = this.totalAmountTarget.value
      this.amountSplitValueTarget.setAttribute('max', this.totalAmountTarget.value)
    }

    this.formTargets.forEach((form) => {
      form.querySelector('[data-percentage-total-amount]').setAttribute('data-percentage-total-amount', this.totalAmountTarget.value)
    })
    this.amountSplitValueTargets.forEach((splitAmount) => { 
      splitAmount.dispatchEvent(new Event('change'))
    })
  }

  split(event) {
    event.preventDefault()
    let newForm = this.formTarget.cloneNode(true)
    newForm.classList.add('mt-2', 'pt-3', 'border-t', 'border-dashed', 'border-grey-dark')
    newForm.innerHTML = newForm.innerHTML.replace(/\[split_attributes\]\[0\]/g, '[split_attributes]['+ this.formTargets.length +']')
    newForm.innerHTML = newForm.innerHTML.replace(/split_attributes_0/g, 'split_attributes_'+ this.formTargets.length)
    newForm.querySelector('input[name*="[amount_value]"]').value = this.availableAmount
    newForm.querySelector('input[name*="[percent_value]"]').value = Math.round(this.availableAmount / this.totalAmountTarget.value * 100)
    newForm.querySelector('input[type="radio"][value="amount"]').checked = this.formTarget.querySelector('input[type="radio"][value="amount"]').checked
    newForm.querySelector('input[type="radio"][value="percent"]').checked = this.formTarget.querySelector('input[type="radio"][value="percent"]').checked
    newForm.querySelector('[data-target="revealed-options.revealingSpace"]').innerHTML = ""
    newForm.querySelector('[data-action="donation-splitter#remove"]').classList.remove('hidden')
    newForm.querySelector('#donation_split_attributes_'+ this.formTargets.length +'_is_deductible_true').checked = true
    this.formTarget.parentNode.insertBefore(newForm, null)
    this.updateAvailableAmount()
  }

  updateAvailableAmount() {
    this.availableAmount = this.totalAmountTarget.value || 0
    this.amountSplitValueTargets.forEach((splitAmount) => {
      this.availableAmount = Math.round((this.availableAmount - splitAmount.value) * 100) / 100
    })
    this.availableAmountTarget.innerHTML = parseFloat(this.availableAmount).toFixed(2)
    if(this.availableAmount > 0) {
      this.splitButtonTarget.disabled = false
    } else {
      this.splitButtonTarget.disabled = true
    }
  }

  remove(event) {
    event.preventDefault()
    if(event.target == event.explicitOriginalTarget) {
      event.target.closest('[data-target="donation-splitter.form"]').remove()
      this.updateAvailableAmount()
    }
  }

  enableSplitAmountValues(event) {
    this.amountSplitValueTargets.forEach((splitAmount) => {
      splitAmount.disabled = false
    })
  }
}
