import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["percentage", "amount"]

  recalculateAmount(event) {
    event.preventDefault()
    this.amountTarget.value = Math.round((this.data.get("totalAmount") / 100 * this.percentageTarget.value) * 100) / 100
    
  }

  recalculatePercentage(event) {
    event.preventDefault()
    this.percentageTarget.value = Math.round(this.amountTarget.value / this.data.get("totalAmount") * 100)
  }
}
